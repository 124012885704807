/* eslint-disable jsx-a11y/alt-text */

import { Col, Row, Tabs, Affix } from "antd";
import { useEffect, useState } from "react";

import { BlogHeader } from "./BlogHeader";
import { BlogTabs } from "./BlogTabs";
import { FeaturedArticle } from "./FeaturedArticle";
import GhostContentAPI from "@tryghost/content-api";
import { InlineStylesModel } from "../../../models/InlineStyleModel";
import { RecentPosts } from "./RecentPosts";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

const styles = {
  articleImage: {
    height: "100%",
    width: "100%",
    border: "1px solid rgba(164,164,164,.35)",
    padding: 5,
    borderRadius: 10,
  },
  articleImageCol: {
    // paddingsRight: '1vw'
  },
  featureArticleAuthor: {
    fontSize: ".75rem",
    color: "rgb(164,164,164)",
    fontWeight: 700,
    marginBottom: ".5vh",
  },
  featureArticleTag: {
    color: "white",
    fontWeight: 600,
    marginBottom: ".5vh",
  },
  featureArticleTitle: {
    color: "white",
    fontSize: "1.85rem",
    fontWeight: 600,
    marginBottom: ".5vh",
  },
  featureArticleExcerpt: {
    fontSize: "1rem",
    // lineHeight: '1rem',
    color: "rgb(164,164,164)",
  },
  ourPicksHeader: {
    color: "white",
    fontSize: "26px",
    marginTop: ".5vh",
  },
  ourPicksSubHeader: {
    color: "rgb(164,164,164)",
    fontSize: "16px",
    fontWeight: 399,
    marginBottom: "2vh",
  },
  rowContainer: {
    width: "60vw",
    margin: "auto",
    marginTop: "4vh",
  },
  subFeatureArticleTitle: {
    color: "white",
    fontSize: "1.5rem",
    fontWeight: 600,
    marginTop: "1vh",
  },
  subArticleImage: {
    height: "100%",
    width: "100%",
    border: "1px solid rgba(164,164,164,.35)",
    padding: 5,
    borderRadius: 10,
  },
  featureContainer: {
    marginTop: "4vh",
    // borderBottom: "1px solid rgba(164,164,164,.35 )",
    paddingBottom: 20,
    // borderRight: '1px solid rgb(164,164,164)'
  },
};

export const Ghost = () => {
  const [articles, setArticles] = useState([]);
  const [featureArticle, setFeatureArticle] = useState(null);
  const [articlesGroupedByTag, setArticlesGroupedByTag] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState("Home");

  useEffect(() => {
    const callGhost = async () => {
      const api = new GhostContentAPI({
        host: "https://jesse-runner.ghost.io",
        key: "da868f5a2dddf4a1f2c377e15d",
        version: "v5",
      });

      api.posts
        .browse({ limit: "all", include: "tags,authors" })
        .then((posts) => {
          let articles = [];
          let tagKeys = [];
          posts.forEach((post) => {
            if (post.featured) {
              setFeatureArticle(post);
            }
            // This if statement will attempt to store all unique tag keys into tagKeys array
            if (post.tags.length) {
              const tagExistsInKeyArray = tagKeys.find(
                (key) => key === post.tags[0].slug
              );
              if (!tagExistsInKeyArray) {
                tagKeys.push(post.tags[0].slug);
              }
            }
            articles.push(post);
            console.log(post);
          });
          // The two lines below initalize our string indexed object to the article keys and gives each key an empty array
          // featured is explicitly defined right now because featured is a checkbox not a user tag
          let groupArticlesByTag = {};
          tagKeys.forEach((key) => (groupArticlesByTag[key] = []));
          // groupArticlesByTag["featured"] = [];
          posts.forEach((post) => {
            // if (post.featured) {
            //   groupArticlesByTag["featured"].push(post);
            // }
            if (post.tags.length) {
              const postTag = post.tags[0].slug;
              groupArticlesByTag[postTag].push(post);
            }
          });
          setArticlesGroupedByTag(groupArticlesByTag);
          setArticles(articles);
          console.log(posts);
          setRecentPosts(posts.filter((post) => !post.featured));
          console.log(
            "results",
            posts.filter((post) => !post.featured)
          );
        })
        .catch((err) => {
          console.error(err);
        });
    };

    callGhost();
  }, []);

  const featuredHeadlineArticle =
    featureArticle !== null ? featureArticle : null;

  if (featuredHeadlineArticle !== null && articlesGroupedByTag !== null) {
    return (
    <>
    {window.innerWidth > 480 ? (
      <div style={styles.rowContainer}>
      <BlogTabs
        featureArticle={featureArticle}
        recentPosts={recentPosts}
        articlesGroupedByTag={articlesGroupedByTag}
      />
    </div>
    ):(
      <div>
        <BlogTabs
          featureArticle={featureArticle}
          recentPosts={recentPosts}
          articlesGroupedByTag={articlesGroupedByTag}
        />
      </div>
    )}
      
      </>
    );
  } else {
    return null;
  }
};
