import { Col, Row } from "antd";
import { useEffect, useState } from "react";

import { MediaTabs } from "./MediaTabs";
import { getYoutubeChannelVideos } from "../../../services/YoutubeService";

const styles = {
  rowContainer: {
    width: "60vw",
    margin: "auto",
    marginTop: "4vh",
  },
};

export const Media = () => {
  const [videoList, setVideoList] = useState<any>(null);

  useEffect(() => {
    const asyncFetchChannelInformation = async () => {
      const dcaChannel = await getYoutubeChannelVideos(
        "UC_bG7yHgT_xOUKvI2Hvo6Vg"
      );
      const upddChannel = await getYoutubeChannelVideos(
        "UCzECtg05OBc2sE1KsRnHK7g"
      );
      setVideoList([dcaChannel, upddChannel]);
    };

    if (videoList === null) {
      asyncFetchChannelInformation();
    }
  });

  return (
    <div style={styles.rowContainer}>
      <MediaTabs
        videoList={videoList}
        channelTabNames={["Daily Crypto Analysis", "Upside Down Data"]}
      />
    </div>
  );
};
