import React from 'react';

interface Props {
    cellValue : number,
    type?: string
}

const styles = {
    lightRedFont: {
      color: "#F6BDC0",
    },
    darkRedFont: {
      color: "#DC1C13",
    },
    lightGreenFont: {
      color: "#ABE098",
    },
    yellow: {
      color: "yellow",
    },
    darkGreenFont: {
      color: "#2EB62C",
    },
    whiteFont: {
      color: "white",
    },
  };

const MoblieTableCell : React.FC<Props> = ({cellValue, type}) =>{

    const style = (cellValue : number) =>{

        if(cellValue < -2.5 )
        {
            return styles.darkGreenFont
        }
        else if(cellValue > -2.5 && cellValue <-1.5)
        {
            return styles.lightGreenFont;
        }
        else if(cellValue > -0.5 )
        {
            return styles.whiteFont;
        }else
        {
            return styles.lightRedFont; 
        }

    }

   return(
    <>
    {Number.isInteger(cellValue) == false ? (<a style={style(cellValue)}>{cellValue>0 && <>+</>}{cellValue.toFixed(2)}</a>) : (<a>{cellValue}</a>)}
    </>
   )
}

export default MoblieTableCell;