import React,{useState, useEffect} from 'react';
import 'antd/dist/antd.css';
import { Table,Spin } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import MoblieTableCell from './MoblieTableCell';
import MBICell from './Cell_components/MBICell';
import TCICell from './Cell_components/TCICell';
import TrendConfident from './Cell_components/TrendConfident'
import MDC_Cell from './Cell_components/MDC-Cell';

interface DataType {
  key: React.Key;
  name: string | string[];
  dataIndex:string,
  title: string,
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Asset',
    dataIndex: ['symbol','image'],
    key: 'name',
  render: (text,data) => <span style={{display:"flex",justifyContent:"center",alignItems:"center"}}><img style={{height:"1.25rem","marginRight":"5px"}} src={data['image']}></img><a style={{fontWeight:"bold",color:"#fff"}}>{data['symbol'].toUpperCase()}</a></span>,
    fixed:'left',
  },
  {
    title: 'Price',
    dataIndex: 'current_price',
    key: 'price',
    render: text => <a style={{color:"#fff"}}>{text}</a>,
  },
  {
    title: 'MCap',
    dataIndex: 'market_cap',
    key: 'MCap',
    render: text => <a style={{color:"#fff"}}>{text}</a>,
  },
  {
    title: 'Short',
    dataIndex:'UDPI_ST',
    key: 'short',
    render : text => <MoblieTableCell type='short' cellValue={parseFloat(text)}/>
  },
  {
    title: 'Medium',
    dataIndex:'UDPI_MT',
    key: 'medium',
    render: text => <MoblieTableCell cellValue={parseFloat(text)}/>
  },
  {
    title: 'Long',
    dataIndex:'UDPI_LT',
    key: 'long',
    render: text => <MoblieTableCell cellValue={parseFloat(text)}/>
  },
  {
    title: 'MBI',
    dataIndex:'MBI',
    key: 'short',
    render: text => <MBICell cellValue={parseFloat(text)}/>
  },
  {
    title: 'TCI',
    dataIndex:'TCI',
    key: 'tci',
    render: text => <TCICell cellValue={parseFloat(text)}/>
 
  },
  {
    title: 'Trend Confidence',
    dataIndex:['TCI_CV','current_price_unformatted'],
    key: 'tci',
    render: (text, compare) => <TrendConfident value={compare}/>
  },
  {
    title: 'MDC',
    dataIndex:['MDC_CV','current_price_unformatted'],
    key: 'tci',
    render: (text,compare) => <MDC_Cell value={compare}/>,
  },
];

interface Props {
    coinData : any,
    coinSentiment: any
}

const MobileTable: React.FC<Props> = ({ coinData, coinSentiment } :any) => 
{

const [combinedData, setCombinedData] = useState<any>(null);

  useEffect(() => {
    if (combinedData === null && coinSentiment !== null && coinData !== null) {
      const filteredCoinData = coinData.filter((coin: any) => {
        let coinExists = false;
        Object.keys(coinSentiment).forEach((key) => {
          if (key === coin.symbol.toUpperCase()) {
            coinExists = true;
          }
        });
        return coinExists;
      });
      const coinDataWithSentiment = filteredCoinData.map((coin: any) => {
        let sentimentData: any = {};

        Object.keys(coinSentiment).forEach((key) => {
          if (key === coin.symbol.toUpperCase()) {
            sentimentData = coinSentiment[key];
          }
        });
        sentimentData = sentimentData[sentimentData.length - 1];
        let sentimentDataStanrdized: any = {};
        Object.keys(sentimentData).forEach((sentiment: any) => {
          const standardString = sentiment.slice(sentiment.indexOf("_") + 1);
          sentimentDataStanrdized[standardString] = sentimentData[sentiment];
        });

        return { ...coin, ...sentimentDataStanrdized };
      });
      setCombinedData(coinDataWithSentiment);
    }
  }, [combinedData, coinSentiment, coinData]);

    return(
        <>
            {combinedData !== null ? (
                <Table style={{maxWidth:'500vw'}} columns={columns} dataSource={combinedData}  pagination={false} scroll={{x: true, y:"80vh"}}
                />
            ):(
                <div style={{display:"flex", alignItems:"center",justifyContent:'center',marginTop:"3rem"}}>
                    <Spin style={{ paddingBottom: "20vh" }} />
                </div>
            )}
        </>
    );

}

export default MobileTable;

