import { Col, Row } from "antd";

import { InlineStylesModel } from "../../../models/InlineStyleModel";

interface VideoProps {
  embedId: string;
  videoWidth: string;
  videoHeight: string | number;
}

const styles: InlineStylesModel = {
  iframeOuterContainer: {
    textAlign: "center",
    // paddingTop: "1vh",
    // paddingBottom: "1vh",
  },
  videoOuterContainer: {
    border: ".5px solid rgba(164,164,164,.35)",
    borderRadius: 25,
    backgroundColor: "rgb(22,24,29)",
    // height: "calc(100% - 35px)",
    height: "98%",
    // marginTop: "25px",
  },
  videoTitleContainer: {
    fontSize: "16px",
    marginBottom: "0em",
    fontWeight: 400,
    color: "white",
    borderBottom: "1px solid rgba(164,164,164, .5)",
    paddingLeft: 11,
    height: 40,
  },
};

export const YoutubeEmbed = (videoProps: VideoProps) => {
  return (
    <div
      style={{ width: videoProps.videoWidth, ...styles.videoOuterContainer }}
    >
      <Row style={styles.videoTitleContainer} align="middle">
        {"Our Latest Video"}
      </Row>
      <Row
        style={{
          ...styles.iframeOuterContainer,
          height: "85%",
          width: videoProps.videoWidth,
        }}
        align="middle"
        justify="center"
      >
        <iframe
          width="95%"
          height="95%"
          src={`https://www.youtube.com/embed/${videoProps.embedId}`}
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </Row>
    </div>
  );
};
