import axios, { AxiosRequestConfig } from "axios";

export const getYoutubeChannelVideos = async (channelId: string) => {
  const axiosOptions: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "get",
    url: `https://www.googleapis.com/youtube/v3/search?key=AIzaSyBb0BH0GbCl-xoVVcdashHubCjyk2nh8Jw&channelId=${channelId}&part=snippet,id&order=date&maxResults=20
      `,
  };

  const response = await axios(axiosOptions);
  return response.data.items;
};

export const getSingleYoutubeVideo = async (videoId: string) => {
  const axiosOptions: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
    },
    method: "get",
    url: `https://www.googleapis.com/youtube/v3/videos?part=id,snippet&id=${videoId}&key=AIzaSyBb0BH0GbCl-xoVVcdashHubCjyk2nh8Jw
      `,
  };

  const response = await axios(axiosOptions);
  return response.data.items;
};
