import { Col, Row } from "antd";

interface CellPropTypes {
  cellValue: number;
  columnSpan?: number;
  metricName?: string;
}

const styles = {
  lightRedFont: {
    color: "#F6BDC0",
  },
  darkRedFont: {
    color: "#DC1C13",
  },
  lightGreenFont: {
    color: "#ABE098",
  },
  darkGreenFont: {
    color: "#2EB62C",
  },
  whiteFont: {
    color: "white",
  },
};

export const DashboardTableCell = (CellProps: CellPropTypes) => {
  const { cellValue, columnSpan, metricName } = CellProps;

  const determineStyle = (cellValue: number) => {
    if (
      cellValue * -1 > 0 &&
      (metricName === "udpiShort" ||
        metricName === "udpiMid" ||
        metricName === "udpiLong")
    ) {
      if (cellValue < -2.5) {
        return styles.darkGreenFont;
      } else if (cellValue > -0.5) {
        return styles.whiteFont;
      } else {
        return styles.lightGreenFont;
      }
    } else if (
      cellValue * -1 < 0 &&
      (metricName === "udpiShort" ||
        metricName === "udpiMid" ||
        metricName === "udpiLong")
    ) {
      if (cellValue > 2.5) {
        return styles.darkRedFont;
      } else if (cellValue < 0.5) {
        return styles.whiteFont;
      } else {
        return styles.lightRedFont;
      }
    } else if (cellValue * -1 > 0) {
      if (cellValue < -2.5) {
        return styles.darkRedFont;
      } else if (cellValue > -0.5) {
        return styles.whiteFont;
      } else {
        return styles.lightRedFont;
      }
    } else {
      if (cellValue > 2.5) {
        return styles.darkGreenFont;
      } else if (cellValue < 0.5) {
        return styles.whiteFont;
      } else {
        return styles.lightGreenFont;
      }
    }
  };

  const determineStyleMBI = (cellValue: number) => {
    if (cellValue * -1 > 0) {
      if (cellValue < -0.6) {
        return styles.darkRedFont;
      } else if (cellValue > -0.25) {
        return styles.whiteFont;
      } else {
        return styles.lightRedFont;
      }
    } else {
      if (cellValue > 0.6) {
        return styles.darkGreenFont;
      } else if (cellValue < 0.25) {
        return styles.whiteFont;
      } else {
        return styles.lightGreenFont;
      }
    }
  };

  const determineStyleTCI = (cellValue: number) => {
    if (cellValue * -1 > 0) {
      if (cellValue < -0.8) {
        return styles.darkRedFont;
      } else if (cellValue > -0.16) {
        return styles.whiteFont;
      } else {
        return styles.lightRedFont;
      }
    } else {
      if (cellValue > 0.8) {
        return styles.darkGreenFont;
      } else if (cellValue < 0.16) {
        return styles.whiteFont;
      } else {
        return styles.lightGreenFont;
      }
    }
  };

  return (
    <Col
      span={columnSpan}
      style={
        metricName === "TCI"
          ? determineStyleTCI(cellValue)
          : metricName === "MBI"
          ? determineStyleMBI(cellValue)
          : determineStyle(cellValue)
      }
    >
      {cellValue * -1 > 0 ? (
        <Row>{cellValue.toFixed(2)}</Row>
      ) : (
        <Row>{`+${cellValue.toFixed(2)}`}</Row>
      )}
    </Col>
  );
};
