/* eslint-disable jsx-a11y/alt-text */

import { Col, Row } from "antd";

import { useNavigate } from "react-router-dom";

const styles = {
  articleImage: {
    height: "100%",
    width: "100%",
    border: "1px solid rgba(164,164,164,.35)",
    padding: 5,
    borderRadius: 10,
  },
  featureContainer: {
    paddingBottom: 20,
    cursor: "pointer",
    borderBottom: "1px solid rgba(165,165,165,.5)",
  },
  featureArticleAuthor: {
    fontSize: ".75rem",
    color: "rgb(164,164,164)",
    fontWeight: 700,
    marginBottom: ".5vh",
  },
  featureArticleTag: {
    color: "white",
    fontWeight: 600,
    marginBottom: ".5vh",
  },
  featureArticleTitle: {
    color: "white",
    fontSize: "1.75rem",
    fontWeight: 600,
    marginBottom: ".5vh",
  },
  featureArticleExcerpt: {
    fontSize: "1rem",
    color: "rgb(164,164,164)",
  },
  mobileTitle :{
    fontSize:"1.5rem",
  },
  mobileHeader:{
    fontWeight:"700",
    fontSize:"1.75rem"
  }

};

export const FeaturedArticle = ({ featuredPost, showTag }: any) => {
  const navigator = useNavigate();

  const headlineSelected = () => {
    navigator(`${featuredPost.slug}`);
  };

  return (
    <>
    {window.innerWidth > 480 ? (
      <Row style={styles.featureContainer} gutter={32} onClick={headlineSelected}>
      <Col span={10}>
        {showTag ? (
          <Row style={styles.featureArticleTag}>{"Featured Article"}</Row>
        ) : null}
        <Row
          style={styles.featureArticleTitle}
          className="feature-article-title"
        >
          {featuredPost.title}
        </Row>
        <Row style={styles.featureArticleAuthor}>
          {featuredPost.authors.length
            ? `by ${featuredPost.authors[0].name} - ${featuredPost.published_at}`
            : "by Polarity Digital"}
        </Row>
        <Row style={styles.featureArticleExcerpt}>
          <p>{featuredPost.excerpt}</p>
        </Row>
      </Col>
      <Col span={12} style={{ aspectRatio: "16/9" }}>
        <img style={styles.articleImage} src={featuredPost.feature_image} />
      </Col>
    </Row>
    ):(
      <div>
  <Row>
      <Col>
        {showTag && (
          <Row><h1 style={styles.mobileHeader}>{"Featured Article"}</h1></Row>
        ) }
        <div
          style={styles.featureArticleTitle}
          className="feature-article-title"
        >
        <div style={{display:"flex", justifyContent:"center"}}>
          <img height="140px" src={featuredPost.feature_image}/>
        </div>
          {/* <h1 style={styles.mobileTitle}>{featuredPost.title}</h1> */}
          <Row
          style={styles.featureArticleTitle}
          className="feature-article-title"
        >
          {featuredPost.title}
        </Row>
        </div>
        <Row style={styles.featureArticleAuthor}>
          {featuredPost.authors.length
            ? `by ${featuredPost.authors[0].name} - ${featuredPost.published_at}`
            : "by Polarity Digital"}
        </Row>
        <Row style={styles.featureArticleExcerpt}>
          <p>{featuredPost.excerpt.toString().substring(0,100)+"..."}</p>
        </Row>
      </Col>
  </Row>
  </div>
    )}
    

    </>
  );
};
