import "./index.css";

import { App } from "./App";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from "react-router-dom";
import history from "./utils/history";

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

ReactDOM.render(
  <React.StrictMode>
    <div id="container">
      <Auth0Provider
        domain="dev-h-q-gqz4.us.auth0.com"
        clientId="MbNQdIGjWqCe06PHnfMp3oxfNDMZp25o"
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Auth0Provider>
    </div>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
