import "../../../Ghost.css";

import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";

import GhostContentAPI from "@tryghost/content-api";
import { useParams } from "react-router-dom";

const styles = {
  articleImage: {
    height: "100%",
    width: "100%",
    border: "1px solid rgba(164,164,164,.35)",
    padding: 5,
    borderRadius: 10,
  },
  // featureContainer: {
  //   paddingBottom: 20,
  //   cursor: "pointer",
  //   borderBottom: "1px solid rgba(165,165,165,.5)",
  // },
  featureArticleAuthor: {
    fontSize: ".75rem",
    color: "rgb(164,164,164)",
    fontWeight: 700,
    marginBottom: ".5vh",
  },
  featureArticleTag: {
    color: "white",
    fontWeight: 600,
    marginBottom: ".5vh",
  },
  featureArticleTitle: {
    color: "white",
    fontSize: "2.5rem",
    fontWeight: 600,
    marginBottom: ".5vh",
  },
  mobilefeatureArticleTitle: {
    color: "white",
    fontSize: "1.75rem",
    fontWeight: 600,
    marginBottom: ".5vh",
  },
  featureArticleExcerpt: {
    fontSize: "1rem",
    color: "rgb(164,164,164)",
  },
};

export const IndividualPost = () => {
  const { postTitle } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const callGhost = async () => {
      const api = new GhostContentAPI({
        host: "https://jesse-runner.ghost.io",
        key: "da868f5a2dddf4a1f2c377e15d",
        version: "v5",
      });

      api.posts
        .browse({ filter: `slug: ${postTitle}`, include: "tags,authors" })
        .then((posts) => {
          console.log(posts);
          if (posts[0] !== undefined) {
            setArticle(posts[0]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    callGhost();
  }, [postTitle]);

  if (article === null) {
    return (
      <Row
        style={{ width: "40vw", height: "30vh" }}
        justify="center"
        align="middle"
      >
        <Spin />
      </Row>
    );
  }

  return (
    <>
    {window.innerWidth > 480 ? (
      <div
      style={{
        width: "40vw",
        color: "white",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "750px",
        marginTop: "2vh",
      }}
    >
      <Row style={styles.featureArticleTitle} className="feature-article-title">
        {article.title}
      </Row>
      <Row style={styles.featureArticleAuthor}>
        {article.authors.length
          ? `by ${article.authors[0].name} - ${article.published_at}`
          : "by Polarity Digital"}
      </Row>
      <Row style={{ aspectRatio: "16/9", width: "100%" }}>
        <img style={styles.articleImage} src={article.feature_image} />
      </Row>
      {/* <Col span={32}>
        <p>{article.excerpt}</p>
      </Col> */}
      {/* <Col span={10}>
          <Row style={styles.featureArticleTag}>{"Featured Article"}</Row>
          <Row
            style={styles.featureArticleTitle}
            className="feature-article-title"
          >
            {article.title}
          </Row>
          <Row style={styles.featureArticleAuthor}>
            {article.authors.length
              ? `by ${article.authors[0].name} - ${article.published_at}`
              : "by Polarity Digital"}
          </Row>
          <Row style={styles.featureArticleExcerpt}>
            <p>{article.excerpt}</p>
          </Row>
        </Col>
        <Col span={12} style={{ aspectRatio: "16/9" }}>
          <img style={styles.articleImage} src={article.feature_image} />
        </Col> */}

      <div
        style={{ marginTop: "2.5vh" }}
        dangerouslySetInnerHTML={{ __html: `${article.html}` }}
      ></div>
    </div>
    ): 
    // moblie 
    (
      <div
      style={{
        width: "100%",
        color: "white",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "750px",
        marginTop: "2vh",
      }}
    >
      <Row style={styles.mobilefeatureArticleTitle} className="feature-article-title">
        {article.title}
      </Row>
      <Row style={styles.featureArticleAuthor}>
        {article.authors.length
          ? `by ${article.authors[0].name} - ${article.published_at}`
          : "by Polarity Digital"}
      </Row>
      <Row>
        <img style={styles.articleImage} src={article.feature_image} />
      </Row>
      {/* <Col span={32}>
        <p>{article.excerpt}</p>
      </Col> */}
      {/* <Col span={10}>
          <Row style={styles.featureArticleTag}>{"Featured Article"}</Row>
          <Row
            style={styles.featureArticleTitle}
            className="feature-article-title"
          >
            {article.title}
          </Row>
          <Row style={styles.featureArticleAuthor}>
            {article.authors.length
              ? `by ${article.authors[0].name} - ${article.published_at}`
              : "by Polarity Digital"}
          </Row>
          <Row style={styles.featureArticleExcerpt}>
            <p>{article.excerpt}</p>
          </Row>
        </Col>
        <Col span={12} style={{ aspectRatio: "16/9" }}>
          <img style={styles.articleImage} src={article.feature_image} />
        </Col> */}
       <p>{article.excerpt}</p>
      <div
        style={{ marginTop: "2.5vh" }}
        dangerouslySetInnerHTML={{ __html: `${article.html}` }}
      ></div>
    </div>
    )}
    
    </>
  );
};
