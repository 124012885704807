import React from "react";

interface CellProps {
    cellValue: number
}

const styles = {
    lightRedFont: {
      color: "#F6BDC0",
    },
    darkRedFont: {
      color: "#DC1C13",
    },
    lightGreenFont: {
      color: "#ABE098",
    },
    yellow: {
      color: "yellow",
    },
    darkGreenFont: {
      color: "#2EB62C",
    },
    whiteFont: {
      color: "white",
    },
  };

const TCICell : React.FC<CellProps> = ({cellValue})=>{
   
    const style = (cellValue : number) =>{
        if (cellValue * -1 > 0) {
            if (cellValue < -0.8) {
              return styles.darkRedFont;
            } else if (cellValue > -0.16) {
              return styles.whiteFont;
            } else {
              return styles.lightRedFont;
            }
          } else {
            if (cellValue > 0.8) {
              return styles.darkGreenFont;
            } else if (cellValue < 0.16) {
              return styles.whiteFont;
            } else {
              return styles.lightGreenFont;
            }
          }
    }

   return(
    <>
    {Number.isInteger(cellValue) == false ? (<a style={style(cellValue)}>{cellValue>0 && <>+</>}{cellValue.toFixed(2)}</a>) : (<a>{cellValue}</a>)}
    </>
   )
}

export default TCICell;