import "./App.css";
import "./Ghost.css";

import { Button, Col, Layout, Row } from "antd";
import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";

import { AppHeader } from "./AppHeader";
import { Dashboard } from "./components/Pages.tsx/Dashboard/Dashboard";
import { Ghost } from "./components/Pages.tsx/Ghost/Ghost";
import { IndividualPost } from "./components/Pages.tsx/Ghost/IndividualPost";
import { IndividualVideo } from "./components/Pages.tsx/Media/IndividualVideo";
import { InlineStylesModel } from "./models/InlineStyleModel";
import { Media } from "./components/Pages.tsx/Media/Media";
import { MenuContextProvider } from "./components/Common/menu-context";
import { ReactComponent as PolarityDigital } from "./images/polarity2.svg";
import { round } from "mathjs";
import { useAuth0 } from "@auth0/auth0-react";

const styles: InlineStylesModel = {
  contentContainer: {
    backgroundColor: "#0A0B0E",
    width: "85vw",
    margin: "auto",
    minHeight: "100vh",
  },
  header: {
    height: "70px",
    width: "100vw",
    backgroundColor: "#0A0B0E",
    borderBottom: "1px solid rgba(164,164,164,.35)",
    maxWidth: "100%",
  },
  sider: {
    borderRight: "1px solid rgba(164,164,164,.35)",
    backgroundColor: "#0a0c12",
  },
};

const { Header, Content } = Layout;

export const App = () => {
  const [selectedMenu, setSelectedMenu] = useState("dashboard");
  const updateMenu = (menuOption: string) => {
    setSelectedMenu(menuOption);
  };
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const x = round(2.899999999, 3);

  console.log("x", x);

  if (isAuthenticated) {
    return (
      <>
        <Layout style={{ backgroundColor: "#0A0B0E" }}>
          <>
          {window.innerWidth > 480 ?(
            <Header style={styles.header}>
            <MenuContextProvider
              value={{
                selectedMenu,
                updateMenu,
              }}
            >
                <AppHeader />
            </MenuContextProvider>
          </Header>
          ):(
            <div style={{position:"fixed", background:"#222",zIndex:"99999",width:"100%"}}>
              {/* <h2 style={{color:"#fff"}}>Header</h2> */}
                  <Header style={styles.header}>
                    <MenuContextProvider
                      value={{
                        selectedMenu,
                        updateMenu,
                      }}
                    >
                        <AppHeader />
                    </MenuContextProvider>
                </Header>
          
            </div>
          )}
          

          </>
          <>
          {window.innerWidth > 480 ?(
            <Layout style={{ backgroundColor: "#0A0B0E" }}>
            <Content style={styles.contentContainer}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/blog" element={<Ghost />} />
                <Route path="/blog/:postTitle" element={<IndividualPost />} />
                <Route path="/media" element={<Media />} />
                <Route path="/media/:videoId" element={<IndividualVideo />} />
              </Routes>
            </Content>
          </Layout>
          ):(
            <Layout style={{ backgroundColor: "#0A0B0E", marginTop:"5rem" }}>
            <Content style={styles.contentContainer}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/blog" element={<Ghost />} />
                <Route path="/blog/:postTitle" element={<IndividualPost />} />
                <Route path="/media" element={<Media />} />
                <Route path="/media/:videoId" element={<IndividualVideo />} />
              </Routes>
            </Content>
          </Layout>
          )}
          </>
          
        </Layout>
      </>
    );
  } else {
    return (
      <div
        style={{
          backgroundColor: "#0A0B0E",
          height: "100vh",
          width: "100vw",
          paddingTop: "20vh",
        }}
      >
        <div
          style={{
            width: "20vw",
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 15,
            backgroundColor: "rgb(22, 24, 29)",
          }}
        >
          <Row style={{ height: "80px" }}>
            <PolarityDigital />
          </Row>
          <Row justify="center">
            <h1
              style={{
                color: "white",
                fontSize: 28,
                marginBottom: 0,
              }}
            >
              Welcome to Polarity Digital
            </h1>
          </Row>
          <Row justify="center">
            <h3 style={{ color: "grey", fontSize: 16, marginTop: 0 }}>
              Please click below to visit our site
            </h3>
          </Row>
          <Row justify="center">
            <Button
              style={{
                height: "5%",
                width: "7vw",
                cursor: "pointer",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "2vh",
              }}
              onClick={() => loginWithRedirect()}
              type="primary"
            >
              Login
            </Button>
          </Row>
        </div>
      </div>
    );
  }
};
