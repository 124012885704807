import { Col, Row, Tabs,Affix ,Drawer} from "antd";

import { BlogGenericTabContent } from "./BlogGenericTabContent";
import { BlogOverviewTabContent } from "./BlogOverviewTabContent";
import { FeaturedArticle } from "./FeaturedArticle";
import { RecentPosts } from "./RecentPosts";

const { TabPane } = Tabs;

const styles = {
  ourPicksHeader: {
    color: "white",
    fontSize: "26px",
    marginTop: ".5vh",
  },
  ourPicksSubHeader: {
    color: "rgb(164,164,164)",
    fontSize: "16px",
    fontWeight: 399,
    marginBottom: "2vh",
  },
  foo:{
    width:"60px"
  }
};

export const BlogTabs = ({
  featureArticle,
  recentPosts,
  articlesGroupedByTag,
}: any) => {
  const tabPanesJSX =
    articlesGroupedByTag !== null
      ? Object.keys(articlesGroupedByTag).map((key) => (
          <TabPane
            tab={key[0].toUpperCase() + key.substring(1)}
            key={key[0].toUpperCase() + key.substring(1)}
            style={{ fontSize: 20 }}
          >
            <BlogGenericTabContent
              articlesGroupedByTag={articlesGroupedByTag}
              tabKey={key}
            />
          </TabPane>
        ))
      : null;

  return (
    <>
    {window.innerWidth > 480 ? (
      <Tabs defaultActiveKey="Overview">
      <TabPane tab="Overview" key="Overview">
        <FeaturedArticle featuredPost={featureArticle} showTag={true} />
        <Row>
          <h2 style={styles.ourPicksHeader}>{"Latest"}</h2>
        </Row>
        <Row>
          <h3 style={styles.ourPicksSubHeader}>
            {"Recently Published Articles"}
          </h3>
        </Row>
        <RecentPosts recentPosts={recentPosts} />
        <BlogOverviewTabContent articlesGroupedByTag={articlesGroupedByTag} />
      </TabPane>
          {tabPanesJSX}
    </Tabs>
    ):(
     
          <Tabs defaultActiveKey="Overview" style={{position:"relative"}}>
              <TabPane tab="Overview" key="Overview"  >
                <div style={{background:"#000", position:"relative", scrollBehavior:"auto"}}>
                  <FeaturedArticle featuredPost={featureArticle} showTag={true} />
                </div>
              <Row>
                <h2 style={styles.ourPicksHeader}>{"Latest"}</h2>
              </Row>
              <Row>
                <h3 style={styles.ourPicksSubHeader}>
                  {"Recently Published Articles"}
                </h3>
              </Row>
              <RecentPosts recentPosts={recentPosts} />
              <BlogOverviewTabContent articlesGroupedByTag={articlesGroupedByTag} />
            </TabPane>
                {tabPanesJSX}
          </Tabs>
    )}
      

    </>
  );
};
