import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";

import { DashboardTableCell } from "./DashboardTableCell";
import { InlineStylesModel } from "../../../models/InlineStyleModel";

<CaretDownOutlined />;

const styles: InlineStylesModel = {
  coinImage: {
    height: 20,
    width: 20,
    marginLeft: ".5vw",
    marginTop: 5,
    marginBottom: 5,
  },
  coinNameCol: {
    fontWeight: 600,
  },
  headerRow: {
    color: "rgb(146,153,170)",

    paddingBottom: "5px",
    fontSize: 16,
    marginTop: 5,
  },
  headerRowTop: {
    color: "rgb(146,153,170)",
    backgroundColor: "rgb(22,24,29)",
    fontSize: 16,
  },
  headerPadding: {
    paddingLeft: ".5vw",
  },
  individualRowOdd: {
    color: "white",
    // backgroundColor: "#0A0B0E",
  },
  individualRowEven: {
    color: "white",
    backgroundColor: "#0A0B0E",
  },
  individualRowLastEntry: {
    color: "white",
    backgroundColor: "rgb(22,24,29)",
    borderRadius: 25,
  },
  negativeTableValue: {
    color: "#E91F2B",
  },
  positiveTableValue: {
    color: "#2EB62C",
  },
  tabledMainContainer: {
    borderRadius: 25,
    width: "55vw",
    backgroundColor: "rgb(22,24,29)",
  },

  lightRedFont: {
    color: "#F6BDC0",
  },
  darkRedFont: {
    color: "DC1C13",
  },
  lightGreenFont: {
    color: "#ABE098",
  },
  whiteFont: {
    color: "white",
  },
};

export const DashboardTable = ({ coinData, coinSentiment }: any) => {
  const [combinedData, setCombinedData] = useState<any>(null);

  const showNegativeValue = (value: number) => (value * -1 > 0 ? true : false);

  useEffect(() => {
    if (combinedData === null && coinSentiment !== null && coinData !== null) {
      const filteredCoinData = coinData.filter((coin: any) => {
        let coinExists = false;
        Object.keys(coinSentiment).forEach((key) => {
          if (key === coin.symbol.toUpperCase()) {
            coinExists = true;
          }
        });
        return coinExists;
      });
      const coinDataWithSentiment = filteredCoinData.map((coin: any) => {
        let sentimentData: any = {};

        Object.keys(coinSentiment).forEach((key) => {
          if (key === coin.symbol.toUpperCase()) {
            sentimentData = coinSentiment[key];
          }
        });
        sentimentData = sentimentData[sentimentData.length - 1];
        let sentimentDataStanrdized: any = {};
        Object.keys(sentimentData).forEach((sentiment: any) => {
          const standardString = sentiment.slice(sentiment.indexOf("_") + 1);
          sentimentDataStanrdized[standardString] = sentimentData[sentiment];
        });

        return { ...coin, ...sentimentDataStanrdized };
      });
      setCombinedData(coinDataWithSentiment);
    }
  }, [combinedData, coinSentiment, coinData]);

  const headerRow = (
    <Row style={styles.headerRow}>
      {/* <Col style={styles.headerPadding} span={3}></Col> */}
      <Col
        span={8}
        style={{
          textAlign: "center",
          fontSize: 14,
          backgroundColor: "rgba(80,80,80, .35)",
          borderTopLeftRadius: 25,
          borderRight: "1px solid rgba(164, 164, 164, 0.35)",
        }}
      >
        Market
      </Col>
      <Col
        span={6}
        style={{
          textAlign: "center",
          fontSize: 14,
          backgroundColor: "rgba(80,80,80, .35)",
          borderRight: "1px solid rgba(164, 164, 164, 0.35)",
        }}
      >
        UDPI Risk
      </Col>
      <Col
        span={2}
        style={{
          textAlign: "center",
          fontSize: 14,
          backgroundColor: "rgba(80,80,80, .35)",
          borderRight: "1px solid rgba(164, 164, 164, 0.35)",
        }}
      >
        Momentum
      </Col>
      <Col
        span={5}
        style={{
          textAlign: "center",
          fontSize: 14,
          backgroundColor: "rgba(80,80,80, .35)",
          borderRight: "1px solid rgba(164, 164, 164, 0.35)",
        }}
      >
        Trend Confidence
      </Col>
      <Col
        span={3}
        style={{
          textAlign: "center",
          fontSize: 14,
          backgroundColor: "rgba(80,80,80, .35)",
          borderTopRightRadius: 25,
        }}
      >
        MDC
      </Col>

      <Col style={styles.headerPadding} span={3}>Asset</Col>
      <Col span={3}>Price</Col>
      <Col span={2}>MCap</Col>
      <Col span={2}>Short</Col>
      <Col span={2}>Medium</Col>
      <Col span={2}>Long</Col>
      <Col span={2}>MBI</Col>
      <Col span={2}>TCI</Col>
      <Col span={3}>Crit Level</Col>
      <Col span={3}>Crit Level</Col>
    </Row>
  );
  const individualRow = () => {
    const rows: any = [];
    console.log("coin", combinedData);
    if (combinedData !== null && coinData !== undefined) {
      // const top25 = coinData.slice(0, 24);

      combinedData.forEach((coin: any, index: any) => {
        rows.push(
          <Row
            style={
              index === combinedData.length
                ? styles.individualRowLastEntry
                : index % 2 === 0
                ? styles.individualRowOdd
                : styles.individualRowEven
            }
            align="middle"
          >
            <Col span={1}>
              <img style={styles.coinImage} src={coin.image}></img>
            </Col>
            <Col span={2} style={styles.coinNameCol}>
              {coin.symbol.toUpperCase()}
            </Col>
            <Col span={3}>{coin.current_price}</Col>
            <Col span={2}>{coin.market_cap}</Col>
            <DashboardTableCell
              cellValue={parseFloat(coin.UDPI_ST)}
              columnSpan={2}
              metricName="udpiShort"
            />
            <DashboardTableCell
              cellValue={parseFloat(coin.UDPI_MT)}
              columnSpan={2}
              metricName="udpiMid"
            />

            <DashboardTableCell
              cellValue={parseFloat(coin.UDPI_LT)}
              columnSpan={2}
              metricName="udpiLong"
            />
            <DashboardTableCell
              cellValue={parseFloat(coin.MBI)}
              columnSpan={2}
              metricName="MBI"
            />
            <DashboardTableCell
              cellValue={parseFloat(coin.TCI)}
              columnSpan={2}
              metricName="TCI"
            />
            <Col
              span={3}
              style={
                parseFloat(coin.TCI_CV) > coin.current_price_unformatted
                  ? styles.negativeTableValue
                  : styles.positiveTableValue
              }
            >
              <Row align="middle">
                {"$" +
                  parseFloat(coin.TCI_CV)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Row>
            </Col>
            <Col
              span={3}
              style={
                parseFloat(coin.MDC_CV) > coin.current_price_unformatted
                  ? styles.negativeTableValue
                  : styles.positiveTableValue
              }
            >
              <Row align="middle">
                {"$" +
                  parseFloat(coin.MDC_CV)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Row>
            </Col>
          </Row>
        );
      });

      return rows;
    }
    return "test";
  };

  return combinedData !== null && coinData !== undefined ? (
    <div style={styles.tabledMainContainer}>
      {/* {headerRowTop} */}
      {headerRow}
      {individualRow()}
    </div>
  ) : (
    <Row
      style={{ width: "55vw", height: "987px" }}
      justify="center"
      align="middle"
    >
      <Spin style={{ paddingBottom: "20vh" }} />
    </Row>
  );
};
