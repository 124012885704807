import { Col, Row } from "antd";
import { useEffect, useState } from "react";

import { DashboardFeaturedArticle } from "./DashboardFeatureArticle";
import { DashboardPostCard } from "./DashboardPostCard";
import { FeaturedArticle } from "../Ghost/FeaturedArticle";
import GhostContentAPI from "@tryghost/content-api";

const styles = {
  mainContainer: {
    width: "100%",
    marginTop: "2vh",
  },
  cardStyle: {
    // backgroundColor: "rgb(22, 24, 29)",
    // marginTop: "2vh",
    marginTop: "auto",
    marginBottom: "auto",
    borderRadius: 15,
    height: "20vh",
  },
};

export const DashboardBottomRow = () => {
  const [featureArticle, setFeaturedArticle] = useState(null);

  useEffect(() => {
    const callGhost = async () => {
      const api = new GhostContentAPI({
        host: "https://jesse-runner.ghost.io",
        key: "da868f5a2dddf4a1f2c377e15d",
        version: "v5",
      });
      api.posts
        .browse({ limit: "all", include: "tags,authors" })
        .then((posts) => {
          posts.forEach((post) => {
            if (post.featured) {
              setFeaturedArticle(post);
            }
          });
        });
    };
    if (featureArticle === null) {
      callGhost();
    }
  });

  console.log("feature article", featureArticle);

  if (featureArticle === null) {
    return <div>loading...</div>;
  }
  return (
    <Row style={styles.mainContainer} gutter={24} align="middle">
      <Col span={8} style={styles.cardStyle}>
        {/* <Row>
          <Col span={12}>
            <DashboardPostCard post={featureArticle} titleFont={"1.25rem"} />
          </Col>
        </Row> */}
        <DashboardPostCard post={featureArticle} titleFont={"1.25rem"} />
      </Col>
      <Col span={8} style={styles.cardStyle}>
        <Row
          style={{
            ...styles.iframeOuterContainer,
            height: "100%",
            backgroundColor: "rgb(22, 24, 29)",
            borderRadius: 15,
          }}
          align="middle"
          justify="center"
        >
          <iframe
            width="95%"
            height="95%"
            src={`https://www.youtube.com/embed/K_Z5SJ5vw4s`}
            frameBorder="0"
            allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Row>
      </Col>
      <Col span={8} style={styles.cardStyle}>
        <Row
          style={{
            ...styles.iframeOuterContainer,
            height: "100%",
            backgroundColor: "rgb(22, 24, 29)",
            borderRadius: 15,
          }}
          align="middle"
          justify="center"
        >
          <iframe
            width="95%"
            height="95%"
            src={`https://www.youtube.com/embed/K_Z5SJ5vw4s`}
            frameBorder="0"
            allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Row>
      </Col>
    </Row>
  );
};
