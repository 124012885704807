import { Col, Row } from "antd";
import { useEffect, useRef, useState } from "react";

import { DashboardBottomRow } from "./DashboardBottomRow";
import { DashboardStats } from "./DashboardStats";
import { DashboardTable } from "./DashboardTable";
import { InlineStylesModel } from "../../../models/InlineStyleModel";
import { YoutubeEmbed } from "./YoutubeEmbed";
import { fetchTop100Coins } from "../../../services/MarketCapService";
import { getMarketSentiment } from "../../../services/MarketSentimentService";
import { Fragment } from "react";
import MobileTable from "./MobileTable";

const styles: InlineStylesModel = {
  chartSpacing: {
    height: "49%",
  },
  dashboardPageTitle: {
    fontSize: 30,
    fontWeight: 400,
    color: "white",
  },
  dashAndChartContainer: {
    marginTop: "2vh",
  },
  tableSpacing: {
    marginRight: "1vw",
  },
};

export const Dashboard = ({ coinData }: any) => {
  const [top100Coins, setTop100Coins] = useState<any>(null);
  const [coinSentiments, setCoinSentiments] = useState<any>(null);
  const [callingCoinSentiment, setCallingCoinSentiment] = useState<any>(false);
  const [combinedData, setCombinedData] = useState<any>(null);

  const [divHeight, setDivHeight] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const getTop100Coins = async () => {
      const coinData = await fetchTop100Coins();
      setTop100Coins(coinData);
    };

    if (top100Coins === null) {
      getTop100Coins();
    }
  }, [top100Coins]);

  useEffect(() => {
    if (ref.current !== null) {
      setDivHeight(ref.current.clientHeight / 2);
    }
  }, []);

  useEffect(() => {
    const getCoinSentiment = async () => {
      const coinSentiments = await getMarketSentiment();
      setCoinSentiments(coinSentiments);
    };

    if (coinSentiments === null && callingCoinSentiment === false) {
      setCallingCoinSentiment(true);
      getCoinSentiment();
    }
  });

  useEffect(() => {
    if (
      combinedData === null &&
      coinSentiments !== null &&
      top100Coins !== null
    ) {
      console.log("sentiment", coinSentiments);
      const filteredCoinData = top100Coins.filter((coin: any) => {
        let coinExists = false;
        Object.keys(coinSentiments).forEach((key) => {
          if (key === coin.symbol.toUpperCase()) {
            coinExists = true;
          }
        });
        return coinExists;
      });
      console.log("test 1", filteredCoinData);
      const coinDataWithSentiment = filteredCoinData.map((coin: any) => {
        let sentimentData: any = {};

        Object.keys(coinSentiments).forEach((key) => {
          if (key === coin.symbol.toUpperCase()) {
            sentimentData = coinSentiments[key];
          }
        });
        sentimentData = sentimentData[sentimentData.length - 1];
        let sentimentDataStanrdized: any = {};
        Object.keys(sentimentData).forEach((sentiment: any) => {
          const standardString = sentiment.slice(sentiment.indexOf("_") + 1);
          sentimentDataStanrdized[standardString] = sentimentData[sentiment];
        });

        return { ...coin, ...sentimentDataStanrdized };
      });
      setCombinedData(coinDataWithSentiment);
    }
  }, [combinedData, coinSentiments, top100Coins]);

  return (
    <>
  
        {window.innerWidth >480 ? (
          <div style={{display:"flex",flexDirection:"row" }}> 
          <DashboardTable
            coinData={top100Coins}
            coinSentiment={coinSentiments}
          />

            <Col style={{marginLeft:"2rem"}}>

              <div style={styles.chartSpacing}>
                <YoutubeEmbed
                  videoHeight={"27vh"}
                  videoWidth="25vw"
                  embedId="wpXkbHiSUnI"
                />
              </div>
            </Col>
            </div>
        // </div>
        ):(
          <div className="mobile-table ">
            <div className="moblie-layout"style={{marginTop:"4.5rem",zIndex:"999"}}>
            <MobileTable 
              coinData={top100Coins}
              coinSentiment={coinSentiments}
            />
          {/* <div style={{marginTop:"1rem", marginBottom:"1rem"}} >
            <YoutubeEmbed
              videoWidth="100%"
              videoHeight={"100%"}
              embedId="wpXkbHiSUnI"
            />
          </div> */}
    
            </div>
        </div>
        )}
          {/* <p style={{color:"#fff"}}>{window.innerWidth}</p> */}
          {window.innerWidth >480 && (
            <DashboardBottomRow />
          )}
       
    </>
  );
};
