import { Col, Row } from "antd";

import { HeaderMenu } from "./HeaderMenu";
import { InlineStylesModel } from "./models/InlineStyleModel";
import { ReactComponent as PolarityDigital } from "./images/polarity2.svg";
import { StockOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

import logo from "./images/polarity.svg";

const styles: InlineStylesModel = {
  rowContainer: {
    height: "60px",
    width: "85vw",
    marginLeft: "auto",
    marginRight: "auto",
  },
  linkColor:{
    color:"#fff"
  },
  links:
  { display:"flex",
    flexDirection:"row",
    marginLeft:"7px"
  },
  navStyle:
  {
    display:"flex", 
  alignItems:"center"
}
  

};

export const AppHeader = () => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  return (
    <>
    {window.innerWidth > 480 ? (
      <Row style={styles.rowContainer} align="middle">
      <Col style={{ height: "100%" }}>
        <PolarityDigital style={{ height: "100%", width: "100%" }} />
      </Col>
      <Col>
        <HeaderMenu />
      </Col>
    </Row>
    ):(
      <div>
        <nav>
            <div style={styles.navStyle} className="logo">
              <Link to="/">
                  <img height="50px" src={logo} alt="" />
              </Link>
              <div style={styles.links}>
                <li><Link style={styles.linkColor} to="/">Dashboard</Link></li>
                <li><Link style={styles.linkColor} to="/blog">Blog</Link></li>
                <li><Link style={styles.linkColor} to="/media">Media</Link></li>
              </div>
            </div>

           
        </nav>
      </div>
    )}
      
    </>
  );
};
