import React from "react";
import { useEffect, useState } from "react";
import { InlineStylesModel } from "../../../../models/InlineStyleModel";

interface Props{
    value: {}
}

const styles : InlineStylesModel = {
    negativeTableValue: {
        color: "#E91F2B",
      },
      positiveTableValue: {
        color: "#2EB62C",
      },
}

const TrendConfident: React.FC <Props> =({value}) =>{

    const [isNegative, setIsNegative] = useState(false);
    useEffect(()=>{
        if(parseFloat(value['TCI_CV']) > parseFloat(value['current_price_unformatted']) )
        {
            setIsNegative(true)
        }
    },[])

    return(
        <a style={isNegative ? styles.negativeTableValue : styles.positiveTableValue}>
            {parseFloat(value['TCI_CV']).toFixed(2)}
        </a>
    )
}

export default TrendConfident;