import { MediaChannelContent } from "./MediaChannelContent";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const styles = {
  ourPicksHeader: {
    color: "white",
    fontSize: "26px",
    marginTop: ".5vh",
  },
  ourPicksSubHeader: {
    color: "rgb(164,164,164)",
    fontSize: "16px",
    fontWeight: 399,
    marginBottom: "2vh",
  },
};

export const MediaTabs = ({ videoList, channelTabNames }: any) => {
  console.log("video list", videoList);
  const tabPanesJSX =
    videoList !== null
      ? channelTabNames.map((channel: string, index: number) => (
          <TabPane tab={channel} key={channel} style={{ fontSize: 20 }}>
            <MediaChannelContent videoList={videoList[index]} />
          </TabPane>
        ))
      : null;

  return <Tabs defaultActiveKey="Overview">{tabPanesJSX}</Tabs>;
};
