import { Col, Row } from "antd";
import { useEffect, useState } from "react";

import { getSingleYoutubeVideo } from "../../../services/YoutubeService";
import { useParams } from "react-router-dom";

const styles = {
  borderRow: {
    borderBottom: "1px solid rgba(164, 164, 164, 0.35)",
    marginTop: "1vh",
    marginBottom: "1vh",
  },
  rowContainer: {
    width: "40vw",
    margin: "auto",
    marginTop: "4vh",
    height: "30vh",
    // textAlign: "center",
  },
  videoDescription: {
    // color: "rgb(100, 116, 139)",
    color: "white",
    fontSize: 16,
    width: "80%",
    // width: "32vw",
    marginLeft: "auto",
    marginRight: "auto",
  },
};

export const IndividualVideo = () => {
  const { videoId } = useParams();
  const [video, setVideo] = useState<any>(null);

  useEffect(() => {
    window.scrollTo(0,0)
    const fetchSingleVideo = async () => {
      if (videoId !== undefined) {
        const videoData = await getSingleYoutubeVideo(videoId as string);
        console.log("single video", videoData);
        setVideo(videoData);
      }
    };

    if (video === null) {
      fetchSingleVideo();
    }
  });

  if (video === null) {
    return <div>loading...</div>;
  }

  return (
    <>
    {window.innerWidth > 480 ? (
      <div style={styles.rowContainer}>
      <Row style={{ height: "100%", width: "100%" }} justify="center">
        <iframe
          width="80%"
          height="95%"
          src={`https://www.youtube.com/embed/${video[0].id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </Row>
      <Row style={styles.borderRow}></Row>
      <Row style={styles.videoDescription} justify="center">
        {video[0].snippet.description}
      </Row>
    </div>
    ):(
      <div style={{overflow:"hidden"}}>
      <Row justify="center">
        <Col>
        <iframe
          // width="100%"
          // height="100%"
          src={`https://www.youtube.com/embed/${video[0].id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
        </Col>
      </Row>
      <Row style={styles.borderRow}></Row>
        <Row style={styles.videoDescription} justify="center">
          <Col>
            {video[0].snippet.description}
          </Col>
        </Row>
      
    </div>
    )}
    
    </>
  );
};
