/* eslint-disable jsx-a11y/alt-text */

import { Col, Row, Typography } from "antd";

import { useNavigate } from "react-router-dom";

const styles = {
  borderRow: {
    borderBottom: "1px solid rgba(164, 164, 164, 0.35)",
  },
  individualRow: {
    marginBottom: "2vh",
    marginTop: "2vh",
    cursor: "pointer",
  },
  videoDate: {
    color: "rgb(100, 116, 139)",
    fontSize: 16,
  },
  videoDescription: {
    color: "rgb(100, 116, 139)",
    fontSize: 16,
  },
  videoImage: {
    height: "100%",
    width: "100%",
    borderRadius: 15,
  },
  videoTitle: {
    color: "white",
    fontSize: 18,
  },
};

export const MediaChannelContent = ({ videoList }: any) => {
  const navigator = useNavigate();
  //   console.log("video list", videoList);
  const videoListJSX = videoList.map((video: any) => {
    const date = video.snippet.publishedAt.split("T")[0];
    const [year, month, day] = date.split("-");
    let dateFormatted = [month, day, year].join("/");
    dateFormatted =
      dateFormatted[0] === "0"
        ? dateFormatted.slice(1, dateFormatted.length)
        : dateFormatted;

    const mediaSelected = () => {
      navigator(`${video.id.videoId}`);
    };

    return (
      <>
      {window.innerWidth > 480 ? (
        <>
          <Row gutter={32} style={styles.individualRow} onClick={mediaSelected}>
          <Col span={6}>
            <img
              src={video.snippet.thumbnails.medium.url}
              style={styles.videoImage}
            />
          </Col>
          <Col span={18}>
            <Row style={styles.videoTitle}>{video.snippet.title}</Row>
            <Row style={styles.videoDate}>{dateFormatted}</Row>
            {/* <Row> */}
            <Typography.Paragraph
              // ellipsis={{ rows: 5 }}
              style={styles.videoDescription}
            >
              {video.snippet.description}
            </Typography.Paragraph>
            {/* </Row> */}
          </Col>
        </Row>
        <Row style={styles.borderRow}></Row>
        </>
      ):(
        <>
        <Row gutter={32} style={styles.individualRow} onClick={mediaSelected}>
         <Row>
            <Col span={32}>
              <img
                src={video.snippet.thumbnails.medium.url}
                style={styles.videoImage}
              />
            </Col>
          </Row>
          <Row>
          <Col span={32}>
            <Row style={styles.videoTitle}>{video.snippet.title}</Row>
            <Row style={styles.videoDate}>{dateFormatted}</Row>
            {/* <Row> */}
            <Typography.Paragraph
              // ellipsis={{ rows: 5 }}
              style={styles.videoDescription}
            >
              {video.snippet.description}
            </Typography.Paragraph>
            {/* </Row> */}
          </Col>
          </Row>
        </Row>
        <Row style={styles.borderRow}></Row>
        </>
      )}
        
      </>
    );
  });

  return videoListJSX;
};
