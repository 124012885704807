/* eslint-disable jsx-a11y/alt-text */

import { Col, Row, Typography } from "antd";

const styles = {
  postAuthor: {
    fontSize: ".75rem",
    color: "rgb(164,164,164)",
    fontWeight: 700,
    marginBottom: ".5vh",
  },
  title: {
    color: "white",
    fontSize: "1.2rem",
    fontWeight: 600,
    marginTop: "1vh",
  },
  subArticleImage: {
    // height: "200px",
    height: "90%",
    width: "100%",
    // border: "1px solid rgba(164,164,164,.35)",
    padding: 5,
    borderRadius: 10,
  },
  postExcerpt: {
    fontSize: ".85rem",
    // lineHeight: '1rem',
    color: "rgb(164,164,164)",
  },
};

export const DashboardPostCard = ({ post, titleFont }: any) => {
  return (
    <>
      <Row
        style={{
          // height: "200px",
          backgroundColor: "rgb(22, 24, 29)",
          height: "100%",
          borderRadius: 15,
        }}
        align="middle"
      >
        <Col span={12}>
          <img style={styles.subArticleImage} src={post.feature_image} />
        </Col>
        <Col style={{ ...styles.title, fontSize: titleFont }} span={12}>
          {post.title}
          <Row style={styles.postAuthor}>
            {post.authors.length
              ? ` by ${post.authors[0].name} - ${post.published_at}`
              : "by Polarity Digital"}
          </Row>
          <Row>
            <Typography.Paragraph
              ellipsis={{ rows: 5 }}
              style={styles.postExcerpt}
            >
              {post.excerpt}
            </Typography.Paragraph>
          </Row>
        </Col>
      </Row>
    </>
  );
};
