import {
  AppstoreOutlined,
  ContainerOutlined,
  DashboardOutlined,
  LineChartOutlined,
  MessageOutlined,
  ReadOutlined,
} from "@ant-design/icons";

import { InlineStylesModel } from "./models/InlineStyleModel";
import { Menu } from "antd";
import { MenuContext } from "./components/Common/menu-context";
import type { MenuProps } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const styles: InlineStylesModel = {
  mainMenuStyle: {
    backgroundColor: "#0A0B0E",
    color: "#64748b",
    borderRight: "none",
    fontSize: "16px",
    fontWeight: 500,
    border: "none",
    paddingRight: "7.5vw",
  },
};

export const HeaderMenu = () => {
  const { updateMenu } = useContext(MenuContext);
  const navigator = useNavigate();

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem("Dashboard", "dashboard"),
    // getItem("Risk", "risk"),
    getItem("Blog", "blog"),
    getItem("Media", "media"),
  ];

  const onMenuClick = (value: any) => {
    // updateMenu(value.key);
    switch (value.key) {
      case "dashboard": {
        navigator("/");
        break;
      }
      case "blog": {
        navigator("/blog");
        break;
      }
      // case "risk": {
      //   navigator("/risk");
      //   break;
      // }
      case "media": {
        navigator("/media");
        break;
      }
    }
  };

  return (
    <Menu
      onClick={onMenuClick}
      style={styles.mainMenuStyle}
      defaultSelectedKeys={["dashboard"]}
      defaultOpenKeys={["sub1"]}
      mode="horizontal"
      items={items}
    />
  );
};
