import React, {useEffect, useState} from "react";
import { InlineStylesModel } from "../../../../models/InlineStyleModel";

interface Props{
    value: {}
}

const styles : InlineStylesModel = {
    negativeTableValue: {
        color: "#E91F2B",
      },
      positiveTableValue: {
        color: "#2EB62C",
      },
}

const MDC_Cell: React.FC<Props> = ({value})=>{
    const [isNegative, setIsNegative] = useState(false);
    useEffect(()=>{
        if(parseFloat(value['MDC_CV']) > parseFloat(value['current_price_unformatted']) )
        {
            setIsNegative(true)
        }
    },[])

    return(
        <a style={isNegative ? styles.negativeTableValue : styles.positiveTableValue}>
            {parseFloat(value['MDC_CV']).toFixed(2)}
        </a>
    )
}

export default MDC_Cell;
